import React from "react"
import coverImage from "../images/about/our-mission/our-mission-cover.jpg"
import StandardLayout from "../layouts/standard"

const data = [
  {
    name: "Elegant Series",
    link: "https://s3.amazonaws.com/sty-web/brochure/elegant-series.pdf",
  },
  {
    name: "Handpicked Series",
    link: "https://s3.amazonaws.com/sty-web/brochure/handpicked-series.pdf",
  },
  {
    name: "Solid Colors",
    link: "https://s3.amazonaws.com/sty-web/brochure/Solid-Catalogue.pdf",
  },
  {
    name: "Textures",
    link: "https://s3.amazonaws.com/sty-web/brochure/Texture-Catalogue.pdf",
  },
  {
    name: "Cuboid – Cubicles & Lockers",
    link:
      "https://s3.amazonaws.com/sty-web/brochure/Cuboid-by-Stylam-Book-2020.pdf",
  },
  {
    name: "Fascia – Exterior Cladding",
    link:
      "https://s3.amazonaws.com/sty-web/brochure/Fascia-Stylam-Exterior-Cladding.pdf",
  },
  {
    name: "Granex – Acrylic Solid Surface",
    link: "https://s3.amazonaws.com/sty-web/brochure/Grenex-Stylam-Booklet.pdf",
  },
  {
    name: "Stylam Laminate",
    link:
      "https://s3.amazonaws.com/sty-web/brochure/STYLAM-Decorative-Laminate.pdf",
  },
  {
    name: "Violam Laminate",
    link: "https://s3.amazonaws.com/sty-web/brochure/Violam-Laminate.pdf",
  },
  {
    name: "Viotouch Laminate",
    link:
      "https://s3.amazonaws.com/sty-web/brochure/Viotouch-Decorative-Laminate.pdf",
  },
  {
    name: "Leader Laminate",
    link:
      "https://s3.amazonaws.com/sty-web/brochure/Leader-Decorative-Laminate.pdf",
  },
  {
    name: "TouchMe Laminate",
    link: "https://s3.amazonaws.com/sty-web/brochure/Touch-Me-AFP-Laminate.pdf",
  },
  {
    name: "GlossPro+ Laminate",
    link: "https://s3.amazonaws.com/sty-web/brochure/Gloss-Pro-Laminate.pdf",
  },
  {
    name: "PreLam Board – GlossPro+ & TouchMe",
    link:
      "https://s3.amazonaws.com/sty-web/brochure/AFP-GlossPro-Prelam-Board-Stylam.pdf",
  },
]

export default function Brochures(props) {
  return (
    <StandardLayout title="Catalogues">
      <div className="cover-cont">
        <img src={coverImage} alt="" />
        <div>Brochures</div>
      </div>

      <div className="catalog-head">Brochures</div>

      <div className="brochures-cont">
        {data.map(d => (
          <div>
            <div className="brochure-title">{d.name}</div>
            <a target="_blank" href={d.link} className="brochure-link">
              View
            </a>
          </div>
        ))}
      </div>
    </StandardLayout>
  )
}
